var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "phone-refund-setting"
    },
    [
      _c(
        "div",
        { staticClass: "phone-refund-setting__box" },
        [
          _c("span", [_vm._v("自动退款：")]),
          _c("el-switch", {
            on: { change: _vm.onSave },
            model: {
              value: _vm.status,
              callback: function($$v) {
                _vm.status = $$v
              },
              expression: "status"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "phone-refund-setting__box" },
        [
          _c("span", [_vm._v("退款类型：")]),
          _c(
            "el-checkbox-group",
            {
              attrs: { disabled: !_vm.status },
              on: { change: _vm.onSave },
              model: {
                value: _vm.type,
                callback: function($$v) {
                  _vm.type = $$v
                },
                expression: "type"
              }
            },
            [
              _c("el-checkbox", { attrs: { label: 1 } }, [_vm._v("慢充")]),
              _c("el-checkbox", { attrs: { label: 2 } }, [_vm._v("快充")])
            ],
            1
          )
        ],
        1
      ),
      _c("p", { staticStyle: { color: "#666" } }, [
        _vm._v("说明：开启自动退款，超时并反馈失败的订单系统自动退款")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }